import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  mobilePromotion,
  promotions1,
  promotions2,
  promo1,
  promo2,
  promo3,
  promo4,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-3px">
          <CFImage src={mobilePromotion} w="90%" alt="Promotions" />
        </CFView>

        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={promo1}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pb="25px"
          />
          <CFImage
            src={promo2}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pb="25px"
          />
          <CFImage
            src={promo3}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pb="25px"
          />
          <CFImage
            src={promo4}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pb="25px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px">
          <CFImage src={promotions1} w="100%" alt="Promotions" />
          <CFImage src={promotions2} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
