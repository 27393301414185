import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ViewMenuButton from './ViewMenuButton'
import {
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
  orderDeliveryButton,
} from 'images'
import DeliveryModal from './DeliveryModal'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          maxHeight="800px"
          image={`url(${mobileHero}) top / cover no-repeat`}
          column
          justifyBetween
          alignCenter
          textCenter
        >
          <CFView column justifyStart alignCenter h="90vh" mt="15px">
            <CFImage src={mobileHeroText} w="100%" alt="heroText" />
          </CFView>
          <CFView mb="5px" pulsate>
            <OrderPickupButton />
          </CFView>
          <CFView
            hover
            onClick={() => setShowModal(true)}
            column
            center
            mb="15px"
          >
            <CFImage
              w="90%"
              maxWidth="300px"
              src={orderDeliveryButton}
              alt="Order delivery"
            />
          </CFView>
          <CFView mb="15px">
            <ViewMenuButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          maxWidth="1400px"
          image={`url(${hero}) left / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <CFView w="100%" h="600px" column justifyCenter alignStart>
            <CFImage
              src={heroText}
              w="28%"
              minWidth="250px"
              maxWidth="500px"
              alt="heroText"
              ml="75px"
            />
            <CFView mt="2%" ml="75px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView
              hover
              onClick={() => setShowModal(true)}
              column
              center
              ml="75px"
              mt="10px"
            >
              <CFImage
                w="100%"
                maxWidth="280px"
                src={orderDeliveryButton}
                alt="Order delivery"
              />
            </CFView>
            <CFView mt="10px" ml="75px">
              <ViewMenuButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <DeliveryModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
