export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1631982936/ichiban/Group_610_3.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1631982697/ichiban/Group_610_2.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://d2cs5mda6o5lvo.cloudfront.net/viewMenuButton2.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1590261442/general/cflogo.png'
export const about = 'https://d2cs5mda6o5lvo.cloudfront.net/about2.webp'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632711392/sushikinggeorge/contactBg.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1631988648/ichiban/foodGallery.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632713217/sushikinggeorge/herobg.jpg'
export const heroText = 'https://d2cs5mda6o5lvo.cloudfront.net/heroText.webp'
export const locationMap =
  'https://d2cs5mda6o5lvo.cloudfront.net/locationMap.webp'
export const logo = 'https://d2cs5mda6o5lvo.cloudfront.net/webLogo.png'
export const mobileAbout =
  'https://d2cs5mda6o5lvo.cloudfront.net/mobileAbout2.webp'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632714773/sushikinggeorge/mobileContactbg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1631997675/ichiban/foodGalleryMobile.jpg'
export const mobileHero = 'https://d2cs5mda6o5lvo.cloudfront.net/mobileHero.jpg'
export const mobileHeroText =
  'https://d2cs5mda6o5lvo.cloudfront.net/mobileHeroText.webp'
export const mobileMap = 'https://d2cs5mda6o5lvo.cloudfront.net/mobileMap.webp'
export const mobilePromotion =
  'https://d2cs5mda6o5lvo.cloudfront.net/mobilePromo1.webp'
export const orderPickupButton =
  'https://d2cs5mda6o5lvo.cloudfront.net/orderPickupButton2.png'
export const orderDeliveryButton =
  'https://d2cs5mda6o5lvo.cloudfront.net/orderDeliveryButton2.png'
export const promotions1 =
  'https://d2cs5mda6o5lvo.cloudfront.net/promotions1.webp'
export const promotions2 =
  'https://d2cs5mda6o5lvo.cloudfront.net/promotions2.webp'
export const promo1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632714165/sushikinggeorge/promo1.jpg'
export const promo2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632714165/sushikinggeorge/promo2.jpg'
export const promo3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632714165/sushikinggeorge/promo3.jpg'
export const promo4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632714165/sushikinggeorge/promo4.jpg'
export const doorDash =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627344569/masquesolo/doorDash.png'
export const skipTheDishes =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627344569/masquesolo/skipDishes.png'
export const uberEats =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627344569/masquesolo/UberEats.png'

export { default as sushiKooMenu } from './sushiKooMenu.pdf'
