import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { doorDash, skipTheDishes, uberEats } from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'Choose Delivery Provider'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column alignCenter w="100%">
          <a
            href={`https://www.doordash.com/store/sushi-koo-coquitlam-27450059/?cursor=eyJzdG9yZV92ZXJ0aWNhbF9pZCI6bnVsbCwic2VhcmNoX2l0ZW1fY2Fyb3VzZWxfY3Vyc29yIjp7InF1ZXJ5Ijoic3VzaGkga29vIiwiaXRlbV9pZHMiOltdLCJzZWFyY2hfdGVybSI6InN1c2hpIGtvbyIsInZlcnRpY2FsX2lkIjotOTk5LCJ2ZXJ0aWNhbF9uYW1lIjoiYWxsIn0sInN0b3JlX3ByaW1hcnlfdmVydGljYWxfaWRzIjpbXSwiaXNfc2libGluZyI6ZmFsc2UsImZvcmNlX3N0b3JlX2F2YWlsYWJpbGl0eV92MiI6ZmFsc2UsImJ1bmRsZV9nbG9iYWxfc2VhcmNoX2NvbnRleHQiOm51bGx9&pickup=false`}
          >
            <CFView hover>
              <CFImage maxWidth="290px" src={doorDash} alt="Skip the Dishes" />
            </CFView>
          </a>
          <a href={`https://www.skipthedishes.com/sushi-koo-2970-glen`}>
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={skipTheDishes} alt="Door Dash" />
            </CFView>
          </a>
          <a
            href={`https://www.ubereats.com/ca/store/sushi-koo-2970-glen-dr/BXMDb63fRz6wk8d9MjCKfw?diningMode=DELIVERY`}
          >
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={uberEats} alt="UberEats" />
            </CFView>
          </a>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
